<div class="row justify-content-center mainCont">
    <div class="col-sm-auto">
      <div class="card pb-5 loginCont">
        <div class="card-header">
          <img src="assets/imgs/logo.png">
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()" class="px-4">
          <div class="form-group mt-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon5"> <i class="fa fa-envelope"></i> </span>
              </div>
              <input [(ngModel)]="userLogin.email" formControlName="email" type="email" class="form-control" placeholder="Email">
            </div>
            <!-- condition for Name/Email-->
          <div *ngIf="loginForm.controls.email.errors && isError">
            <p *ngIf="loginForm.controls.email.errors.required" class="error">*This field is required</p>
            <p *ngIf="loginForm.controls.email.errors.pattern" class="error">*Email is not valid</p>
        </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon6"> <i class="fa fa-lock"></i> </span>
              </div>
              <input [(ngModel)]="userLogin.password" formControlName="password" type="password" class="form-control" placeholder="********">
            </div>
             <!-- condition for password-->
          <div *ngIf="loginForm.controls.password.errors && isError">
            <p class="error">*This field is required</p>
        </div>
          </div>


          <button type="submit" class="btn loginbtn mt-1">SIGN IN</button>
          <div class="text-center">
            <!-- <p class="primary pointer pt-3" routerLink="/signup"><small>Don't have an account?</small><span class="bold pl-2">SIGN UP</span></p> -->
            <p class="primary pointer pt-4" routerLink="/forget-password"><small><u>Forget Password?</u></small></p>
            </div>
        </form>
      </div>
    </div>
  </div>
  