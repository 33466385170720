import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProceduremeDbDataService } from '../services/procedureme-db-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public userLogin: any = {
    email: '',
    password: ''
  }


  public isError: boolean = false;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor(public router: Router, public service: ProceduremeDbDataService) { }

  ngOnInit(): void {
    if (localStorage.getItem('procedureMe')) {
      this.router.navigate(['/dashboard']);
    }
  }

  onSubmitLogin() {
    if (this.loginForm.valid) {
      this.isError = false;
      var data = this.loginForm.value;

      if (this.userLogin.email == 'admin@gmail.com' && this.userLogin.password == '112233') {
        this.userLogin.email = data.email;
        this.userLogin.password = data.password;
        //local storage
        localStorage.setItem('procedureMe', 'true');
        this.router.navigate(['/dashboard']);
      }
      else {
        alert("Please enter the correct Email/Password");
      }
    }
    else {
      this.isError = true;
    }
  }

}
