<div class="row justify-content-center mainCont">
    <div class="col-sm-auto">
      <div class="card pb-5 loginCont">
        <div class="card-header">
          <img src="assets/imgs/logo.png">
        </div>
        <div class="title">
            <h4>Password Reset</h4>
            <p>Please enter your email to get password reset link.</p>
        </div>
        <form class="px-4">
          <div class="form-group mt-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon4"> <i class="fa fa-envelope"></i> </span>
              </div>
              <input type="email" class="form-control" placeholder="Email">
            </div>
          </div>
          <button routerLink="/dashboard" type="submit" class="btn loginbtn mt-1">SUBMIT</button>
        </form>
      </div>
    </div>
  </div>
  