<div class="row justify-content-center mainCont">
    <div class="col-sm-auto">
      <div class="card loginCont">
        <div class="card-header">
          <img src="assets/imgs/logo.png">
        </div>
        <form [formGroup]="signupForm" (ngSubmit)="onSubmitSignup()" class="px-4">

            <div class="form-group mt-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon7"> <i class="fas fa-user"></i> </span>
                  </div>
                  <input [(ngModel)]="userSignup.firstName" formControlName="firstName" type="text" class="form-control" placeholder="First Name">
                </div>
                <div *ngIf="signupForm.controls.firstName.errors && isError">
                  <p *ngIf="signupForm.controls.firstName.errors.required" class="error">*This field is required</p>
              </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon8"> <i class="fas fa-user"></i> </span>
                  </div>
                  <input [(ngModel)]="userSignup.lastName" formControlName="lastName" type="text" class="form-control" placeholder="Last Name">
                </div>
                <div *ngIf="signupForm.controls.lastName.errors && isError">
                  <p *ngIf="signupForm.controls.lastName.errors.required" class="error">*This field is required</p>
              </div>
              </div>
              
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon9"> <i class="fa fa-envelope"></i> </span>
              </div>
              <input [(ngModel)]="userSignup.email" formControlName="email" type="email" class="form-control" placeholder="Email">
            </div>
            <!-- condition for Name/Email-->
          <div *ngIf="signupForm.controls.email.errors && isError">
            <p *ngIf="signupForm.controls.email.errors.required" class="error">*This field is required</p>
            <p *ngIf="signupForm.controls.email.errors.pattern" class="error">*Email is not valid</p>
        </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon10"> <i class="fa fa-lock"></i> </span>
              </div>
              <input [(ngModel)]="userSignup.password" formControlName="password" type="password" class="form-control" placeholder="Password">
            </div>
             <!-- condition for password-->
          <div *ngIf="signupForm.controls.password.errors && isError">
            <p class="error">*This field is required</p>
        </div>
          </div>
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon11"> <i class="fa fa-lock"></i> </span>
            </div>
            <input [(ngModel)]="userSignup.confirmPassword" formControlName="confirmPassword" type="password" class="form-control" placeholder="Confirm Password">
          </div>
          <!-- condition for password-->
        <div *ngIf="signupForm.controls.confirmPassword .errors && isError">
          <p class="error">*This field is required</p>
      </div>
        </div>


          <button type="submit" class="btn loginbtn">SIGN UP</button>
          <div class="text-center"><p class="primary pointer pt-2" routerLink="/forget-password"><small>Already have an account?</small><span class="bold pl-2" routerLink="/login">LOGIN</span></p></div>
        </form>
      </div>
    </div>
  </div>
  