<app-side-menu></app-side-menu>
<section class="main-cont">
  <div class="row">
    <div class="col-sm-12">
      <div class="main-title">
        <div class="title">
          <h4>All Users</h4>
        </div>
        <div class="search-bar">
          <!-- <button class="btn btn-light" type="button" data-toggle="modal" data-target="#addAgentModal">+ Add New
            User</button> -->
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
            </div>
            <input [(ngModel)]="searchText" type="text" class="form-control" placeholder="Search" aria-label="Search"
              aria-describedby="basic-addon1">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="tabular-view">
        <table class="table table-responsive-sm shadow-sm mb-0">
          <thead>
            <tr>
              <th>Image</th>
              <th>First Name
                <i class="fas fa-chevron-down mx-2"></i>
                <!-- <i class="fas fa-chevron-up mx-2"></i> -->
              </th>
              <th>Last Name
                <i class="fas fa-chevron-down mx-2"></i>
                <!-- <i class="fas fa-chevron-up mx-2"></i> -->
              </th>
              <th>Email
                <i class="fas fa-chevron-down mx-2"></i>
                <!-- <i class="fas fa-chevron-up mx-2"></i> -->
              </th>
              <th>Phone
                <i class="fas fa-chevron-down mx-2"></i>
                <!-- <i class="fas fa-chevron-up mx-2"></i> -->
              </th>
              <th>Status
                <i class="fas fa-chevron-down mx-2"></i>
                <!-- <i class="fas fa-chevron-up mx-2"></i> -->
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of dataHelper.allUsers; let i = index;"
              [hidden]="searchText && !user.details.email.toLowerCase().includes(searchText.toLowerCase()) && !user.details['custom:firstName'].toLowerCase().includes(searchText.toLowerCase())">
              <td><img [src]="user.details['custom:logo'] || 'assets/imgs/icon-avatar.svg'"></td>
              <td>{{user.details['custom:firstName'] || 'N/A' | titlecase}}</td>
              <td>{{user.details['custom:lastName'] || 'N/A' | titlecase}}</td>
              <td>{{user.details.email || 'N/A'}}</td>
              <td>{{user.details['custom:phone'] || 'N/A'}}</td>
              <td>{{user.UserStatus | titlecase}}</td>
              <td>
                <div class="d-flex">
                  <i class="fas fa-lock pointer red" *ngIf="user.details['custom:isBlocked'] === '1'" (click)="currentIndex = i;"
                    data-toggle="modal" data-target="#blockModal"></i>
                  <i class="fas fa-unlock-alt pointer" *ngIf="user.details['custom:isBlocked'] !== '1'" (click)="currentIndex = i;"
                    data-toggle="modal" data-target="#blockModal"></i>
                  <i class="fas fa-arrow-alt-circle-right pointer primary" routerLink="/user-details/{{user.Username}}"></i>
                </div>
              </td>
            </tr>
            <!-- <tr *ngFor="let user of allUsersList" [hidden]="searchText && !user.email.toLowerCase().includes(searchText.toLowerCase()) && !user.name.toLowerCase().includes(searchText.toLowerCase())">
              <td><img [src]="user.profileURL || 'assets/imgs/icon-avatar.svg'"></td>
              <td>{{user.name | titlecase}}</td>
              <td>{{user.email || 'N/A'}}</td>
              <td>{{user.phone || 'N/A'}}</td>
              <td>{{user.country || 'N / A' | titlecase}}</td>
              <td>{{user.gender || 'N / A' | titlecase}}</td>
              <td>
                <div class="d-flex">
                  <i class="fas fa-lock pointer red" *ngIf="user.isBlocked==true" (click)="userData=user;" data-toggle="modal" data-target="#blockModal"></i>
                  <i class="fas fa-unlock-alt pointer" *ngIf="user.isBlocked==false" (click)="userData=user" data-toggle="modal" data-target="#blockModal"></i>
                  <i class="fas fa-arrow-alt-circle-right pointer primary" routerLink="/user-details"></i>
                </div>
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>


<!--Modal block-->
<div class="modal fade" id="blockModal" tabindex="-1" aria-labelledby="blockModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="blockModalLabel">Please Confirm</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="dataHelper.allUsers[currentIndex].details['custom:isBlocked'] !== '1'">Do you want to block this user?</p>
        <p *ngIf="dataHelper.allUsers[currentIndex].details['custom:isBlocked'] === '1'">Do you want to unblock this user?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" id="close" data-dismiss="modal">Close</button>
        <button *ngIf="dataHelper.allUsers[currentIndex].details['custom:isBlocked'] !== '1'" (click)="blockUser()" type="button" class="btn btn-danger"
          data-dismiss="modal">Block</button>
        <button *ngIf="dataHelper.allUsers[currentIndex].details['custom:isBlocked'] === '1'" (click)="blockUser()" type="button" class="btn btn-primary"
          data-dismiss="modal">Unblock</button>
      </div>
    </div>
  </div>
</div>
