import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public userSignup: any = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  }


  public isError: boolean = false;
  signupForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  constructor( public router: Router) { }

  ngOnInit(): void {
  }

  onSubmitSignup(){
    if(this.signupForm.valid){
      this.isError = false;
      this.router.navigate(['/dashboard']);
    }
    else{
      this.isError = true;
    }
  }

}
