import { Component, OnInit } from '@angular/core';
import { DataHelperService } from '../services/data-helper.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public searchText: any;
  public allUsersList: any = []
  public allUsersObj: any = {
    name: 'Jimmy Anderson',
    email: 'jimmyanderson@gmail.com',
    phone: '432-2342-42424-4223',
    country: 'Pakistan',
    gender: 'male',
    isBlocked: false,
  }
  public userData: any = {
    name: '',
    email: '',
    phone: '',
    country: '',
    gender: '',
    isBlocked: null,
  }

  currentIndex = 0;

  constructor(public dataHelper: DataHelperService) { }

  ngOnInit(): void {
    for (var i = 0; i <= 15; i++) {
      this.allUsersList.push(this.allUsersObj);
    }
  }

  blockUser() {
    debugger;

    if(this.dataHelper.allUsers[this.currentIndex].details['custom:isBlocked'] === '1') {
      this.dataHelper.updateUser(this.dataHelper.allUsers[this.currentIndex].Username, '0');
      this.dataHelper.allUsers[this.currentIndex].details['custom:isBlocked'] = '0';
    } else {
      this.dataHelper.updateUser(this.dataHelper.allUsers[this.currentIndex].Username, '1');
      this.dataHelper.allUsers[this.currentIndex].details['custom:isBlocked'] = '1';
    }

    document.getElementById('close').click();
  }

}
