<app-side-menu></app-side-menu>
<section class="main-cont">
  <div class="row">
    <div class="col-sm-12">
      <div class="main-title">
        <div class="title">
          <h4>User Details</h4>
        </div>
      </div>
    </div>
  </div>

  <section>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="row">
            <div class="col-sm-12">
              <div class="profile-image">
                <img [src]="user.details['custom:logo'] || 'assets/imgs/profile.png'">
                <div class="basic-info">
                  <h5 class="bold mb-4">
                    {{user.details["custom:firstName"] + " " + user.details["custom:lastName"] || ''}}</h5>
                  <div class="detail">
                    <p class="primary">Email:</p>
                    <p>{{user.details["email"]}}</p>
                  </div>
                  <div class="detail">
                    <p class="primary">Phone:</p>
                    <p>{{user.details["custom:phone"]}}</p>
                  </div>
                  <!-- <div class="detail">
                    <p class="primary">Country:</p>
                    <p>{{user.details["custom:phone"]}}</p>
                  </div> -->
                  <div class="detail">
                    <p class="primary">City:</p>
                    <p>{{user.details["custom:city"] || "N/A"}}</p>
                  </div>
                  <!-- <div class="detail">
                    <p class="primary">Gender:</p>
                    <p>Male</p>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="teamCont mb-3">
                <h5 class="bold mb-3"><u>Team Details</u> :</h5>
                <div *ngIf="user.admin" class="detail">
                  <p class="primary">Team Admin:</p>
                  <p>{{user.admin.details["custom:firstName"] + " " + user.admin.details["custom:lastName"]}}</p>
                </div>
                <div *ngIf="user.admin" class="detail">
                  <p class="primary">User Role:</p>
                  <p>{{user.roleDetail.role}}</p>
                </div>
                <div *ngIf="!user.admin" class="detail">
                  <p class="primary">Team Members:</p>
                  <p>{{user.team.length}}</p>
                </div>
              </div>
            </div>
            <div *ngIf="!user.admin" class="col-sm-12">
              <!-- <h4 class="bold pb-2">Team Members</h4> -->
              <div class="transactions">
                <div class="tabular-view">
                  <table class="table table-responsive-sm shadow-sm mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th class="text-center">Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let member of user.team">
                        <td>{{member.name | titlecase}}</td>
                        <td>{{member.email}}</td>
                        <td>{{member.phone}}</td>
                        <!-- <td>{{member.role}}</td> -->
                        <td><span *ngFor="let role of member.role">{{role | titlecase}},<br></span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- <div class="row">
        
      <div class="col-sm-3">
        <div class="static-titles">
          <div class="nav flex-column nav-pills text-center px-1" id="v-pills-tab" role="tablist"
            aria-orientation="vertical">
            <a class="nav-link active" id="v-pills-about-tab" data-toggle="pill" href="#v-pills-about" role="tab"
              aria-controls="v-pills-about" aria-selected="true">About</a>
            <a class="nav-link" id="v-pills-transaction-tab" data-toggle="pill" href="#v-pills-transaction" role="tab"
              aria-controls="v-pills-transaction" aria-selected="false">Transactions</a>
          </div>
        </div>
      </div>

      <div class="col-sm-9">
          <div class="static-details">
            <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-about" role="tabpanel" aria-labelledby="v-pills-about-tab">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="profile-image">
                                            <img src="assets/imgs/icon-user.svg">
                                            <p class="primary bold mb-0">Jimmy Anderson</p>
                                            <p class="pointer"><small><u>Edit Profile Image</u></small></p>
                                            <hr>
                                        </div>
                                        <div class="basic-info">
                                            <h5>Basic Info</h5>
                                            <p><span class="bold">Email: </span>Jimmy@example.com</p>
                                            <p><span class="bold">Phone: </span>1234-451-124-12</p>
                                            <p><span class="bold">Gender: </span>Male</p>
                                        </div>
                                        <hr>
                                        <div class="subscription">
                                            <h5>Subscription</h5>
                                            <h3>Monthly</h3>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      
                <div class="tab-pane fade" id="v-pills-transaction" role="tabpanel" aria-labelledby="v-pills-transaction-tab"></div>
          </div>
      </div>

    </div>

    </div> -->

  </section>

</section>
