<section class="navigation">
    <section class="sidebar-nav">
        <nav class="navbar navbar-expand-lg px-0">
            <a class="navbar-brand text-center" routerLink="/home">
                <img src="assets/imgs/logo.png">
                <p class="primary bold mb-0">Procedure Me</p></a>
            <button class="navbar-toggler" data-target="#my-nav" data-toggle="collapse" aria-controls="my-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
            </button>
            <div id="my-nav" class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item pointer" routerLink="/dashboard" [routerLinkActive]="['active']">
                        <i class="fas fa-home"></i>
                        <a class="nav-link">Dashboard</a>
                    </li>
                    <li class="nav-item pointer" routerLink="/users" [routerLinkActive]="['active']">
                        <i class="fas fa-users"></i>
                        <a class="nav-link">All Users</a>
                    </li>
                    <!-- <li class="nav-item pointer" routerLink="/payments" [routerLinkActive]="['active']">
                        <i class="fas fa-dollar-sign"></i>
                        <a class="nav-link">Payments</a>
                    </li>
                    <li class="nav-item pointer" routerLink="/push-notifications" [routerLinkActive]="['active']">
                        <i class="fas fa-bell"></i>
                        <a class="nav-link">Push Notifications</a>
                    </li>
                    <li class="nav-item pointer" routerLink="/static-content" [routerLinkActive]="['active']">
                        <i class="fas fa-shield-alt"></i>
                        <a class="nav-link">Static Content</a>
                    </li> -->
                    <li class="nav-item pointer" (click)="logout()" [routerLinkActive]="['active']">
                        <i class="fas fa-sign-out-alt"></i>
                        <a class="nav-link">Log Out</a>
                    </li>
                </ul>
            </div>
        </nav>
    </section>
    <section class="header-topbar fixed-top">

            <div class="d-flex justify-content-between">
            
                <div class="header-right d-flex ml-auto">
                    <div class="profile d-flex">
                      <div class="d-flex">
                        <a><img src="assets/imgs/icon-avatar.svg" class="rounded-circle"></a>
                        <p class="mb-0 ">John Doe</p>
                      </div>
                      <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown"></button>
                        <div class="dropdown-menu ">
                          <a class="dropdown-item" routerLink="/change-password"> <span><i class="fas fa-key"></i></span> Change Password</a>
                          <a class="dropdown-item" (click)="logout()"> <span><i class="fas fa-sign-out-alt"></i></span> Log Out</a>
                        </div>
                      </div>
                    </div>
                    
                </div>
            </div>
        
    </section>
</section>