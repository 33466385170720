import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DataHelperService {

  public readonly BASE_URL = "https://api.procedureme.com/"

  public allUsers: Array<any> = [];
  public teamMembers: Array<any> = [];

  constructor(private http: HttpClient) {
    this.getUsers();

  }

  getUsers() {
    this.http.get(this.BASE_URL + "getUsers.php")
      .toPromise()
      .then((res: any) => {
        res.forEach(user => {
          var data: any = {};

          user.Attributes.forEach(key => {
            data[key.Name] = key.Value
          });

          user.details = data;
        });

        this.allUsers = res;

        this.getTeams();

        console.log(this.allUsers);
      })
      .catch(err => {

      });
  }

  getTeams() {
    this.http.get(this.BASE_URL + "getTableData.php")
      .toPromise()
      .then((res: any) => {
        debugger;
        this.teamMembers = res;

        res.forEach(user => {
          var roles = [];
          if (user.isAdmin) {
            roles.push("Admin");
          }
          if (user.canEditAccount) {
            roles.push("Edit Account");
          }
          if (user.canEditTeam) {
            roles.push("Edit Team");
          }
          if (user.canEditUnit) {
            roles.push("Edit Unit");
          }
          if (user.canEditProcedure) {
            roles.push("Edit Procedure");
          }
          if (user.canEditProject) {
            roles.push("Edit Project");
          }
          if (user.canEditContact) {
            roles.push("Edit Contact");
          }

          user.role = roles;
        });

        this.allUsers.forEach(user => {
          if (user.details["custom:adminId"]) {
            user.admin = this.allUsers.filter(x => x.Username === user.details["custom:adminId"])[0];
            user.roleDetail = this.teamMembers.filter(x => x.email === user.details["email"])[0];
          } else {
            user.team = this.teamMembers.filter(x => x.adminId === user.Username);
          }
        })
      })
      .catch(err => {

      });
  }

  updateUser(id, status) {
    this.http.get(this.BASE_URL + "updateUserStatus.php?id=" + id + "&status=" + status)
      .toPromise()
      .then((res: any) => {
        debugger;

        console.log(res);
      })
      .catch(err => {
        debugger;
      });
  }
}
