import { Component } from '@angular/core';
import { DataHelperService } from './services/data-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'procedureMe';

  constructor(public dataHelper: DataHelperService) {

  }
}
