<div class="row justify-content-center mainCont">
    <div class="col-sm-auto">
      <div class="card pb-5 loginCont">
        <div class="card-header">
          <img src="assets/imgs/logo.png">
        </div>
        <form class="px-4">
          <div class="form-group">
            <!-- <label for="my-input">Previous Password</label> -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"> <i class="fas fa-key"></i> </span>
              </div>
              <input type="password" class="form-control" placeholder="Previous Password">
            </div>
          </div>
          <div class="form-group">
            <!-- <label for="my-input">New Password</label> -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2"> <i class="fas fa-key"></i> </span>
              </div>
              <input type="password" class="form-control" placeholder="New Password">
            </div>
          </div>
          <div class="form-group">
            <!-- <label for="my-input">Verify Password</label> -->
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon3"> <i class="fas fa-key"></i> </span>
              </div>
              <input type="password" class="form-control" placeholder="Verify Password">
            </div>
          </div>
          <button routerLink="/dashboard" type="submit" class="btn loginbtn mt-1">SAVE</button>
        </form>
      </div>
    </div>
  </div>
  