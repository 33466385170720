import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataHelperService } from '../services/data-helper.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  public teamMembersList: any = []
  public teamMembersObj: any = {
    name: 'johny bravo',
    email: 'johnybravo@gmail.com',
    phone: '123-1242-4241-2411',
    role: 'Edit Projects',
  }
  public memberRolesList: any = [
    'Edit units',
    'Edit Accounts',
    'Edit Procedures',
  ]

  userId: string = "";

  user: any = {};

  constructor(public router: Router, public route: ActivatedRoute, public dataHelper: DataHelperService) {
    route.params.subscribe(res => {
      this.userId = res.id;
      this.user = dataHelper.allUsers.filter(x => x.Username === this.userId)[0];
      debugger;
    });
  }

  ngOnInit(): void {
    for (var i = 0; i <= 10; i++) {
      this.teamMembersObj.role = this.memberRolesList;
      this.teamMembersList.push(this.teamMembersObj);
    }

  }

}
