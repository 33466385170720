<app-side-menu></app-side-menu>

<section class="main-cont">

    <!-- <div class="row">
        <div class="col-sm-12">
            <section class="section-1">
                <div class="row">
                    <div class="col-sm-3 align-self-center">
                        <div class="text-center">
                            <img src="assets/imgs/logo.png">
                        </div>
                    </div>
                    <div class="col-sm-9 align-self-center">
                        <marquee behavior="slide" width="100%" direction="left" height="200px" scrollamount="25">
                            <h4>Welcome Back To The Procedure Me Administrator Panel</h4>
                            <p>You can view and manage your portal through this admin panel.</p>
                        </marquee>
                    </div>
                </div>
            </section>
        </div>
    </div> -->


    <section class="section-2">
        <div class="row">
            <div class="col-sm-12 px-0">
                <div class="row py-4">
                    <div class="col-sm-4">
                        <div class="card pointer" routerLink="/users">
                            <div class="card-body row">
                                <div class="col-sm-8 px-0">
                                    <p>All Users</p>
                                    <h1>{{dataHelper.allUsers.length}}</h1>
                                </div>
                                <div class="col-sm-4">
                                    <div class="icon">
                                        <i class="fas fa-users"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
</section>